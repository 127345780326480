<template>
    <div class="p-password">
        <div class="container-xl d-flex align-items-center justify-content-center">
            <div class="p-password__content d-flex flex-column align-items-start justify-content-center">
                <h1>Password is required</h1>
                <p>Enter the password below to view the personas</p>
                <form @submit.prevent="validatePassword">
                    <label for="passwordLoginInput" class="form-label">Password</label>
                    <div class="input-container">
                        <input type="password" class="form-control input-container__input" id="passwordLoginInput" aria-describedby="passwordLogin" v-model="inputPassword">
                        <button type="button" class="input-container__show-btn" @click="showPassword">
                            <span v-if="hidePassword"><inline-svg :src="require(`../assets/svg/icon-eye-show.svg`)" /></span>
                            <span v-else><inline-svg :src="require(`../assets/svg/icon-eye-hide.svg`)" /></span>
                        </button>
                        <span class="input-container__error-msg"><inline-svg :src="require(`../assets/svg/icon-info.svg`)" /> The password is incorrect. Please try again</span>
                    </div>
                    <Button type="submit" variant="secondary" text="View persona" iconPosition="right" icon="icon-arrow-right" />
                </form>
            </div>
      </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Button from '../components/Button.vue';
import InlineSvg from 'vue-inline-svg';

export default {
    name: 'ClientPassword',

    components: {
        Button,
        InlineSvg,
    },

    computed: {
        ...mapGetters({
            getPassword: 'projects/getPassword',
            projectsFiles: 'projects/projects',
        }),
        projects() {
            return this.projectsFiles
        },
        projectPassword() {
            return this.getPassword
        }
    },

    data() {
        return {
            encryptedPassword: '',
            decryptedPassword: '',
            inputPassword: '',
            secret: "123#$%",

            hidePassword: true,
        }
    },

    methods: {
        validatePassword() {
            this.projects.forEach(project => {
                if (project.name == this.$route.params.clientProjectName) {
                    this.encryptedPassword = project.password;

                    var CryptoJS = require("crypto-js");
                    // decrypt the data and convert to string
                    this.decryptedPassword = CryptoJS.AES.decrypt(
                        this.encryptedPassword,
                        this.secret
                    ).toString(CryptoJS.enc.Utf8);
                }
            })

            if (this.inputPassword === this.decryptedPassword) {
                sessionStorage.setItem('hasAccessToProject', true);
                this.$router.push({ name: 'ClientProject', params: { clientProjectName: this.$route.params.clientProjectName } })
            } else {
                const input = document.querySelector('.input-container__input');
                const msg = document.querySelector('.input-container__error-msg');
                input.classList.add('invalid');
                msg.classList.add('show');
            }
        },

        showPassword() {
            const input = document.querySelector('.input-container__input');
            if(input.type == 'password') {
                input.type = 'text'
                this.hidePassword = false;
            } else {
                input.type = 'password';
                this.hidePassword = true;
            }
        },
    },
}
</script>
